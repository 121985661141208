body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.logo{
  height: 30px;
  margin-right: 10px;
}

.table > tbody > tr > td {
  vertical-align: middle;
}

.win {
  font-weight: bold;
}

.loss {
  font-weight: normal;
}

.scores {
  margin-bottom: 0px;
}

.text-left.text-nowrap.td-score {
  border-top:none;
}

.text-right.text-nowrap.td-score {
  border-top:none;
}

.table > tbody > tr > .red {
  background-color: red;
}

.table > tbody > tr > .yellow {
  background-color: yellow;
}

.table td.fit, 
.table th.fit {
    white-space: nowrap;
    width: 1%;
}
