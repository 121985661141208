.logo{
  height: 30px;
  margin-right: 10px;
}

.table > tbody > tr > td {
  vertical-align: middle;
}

.win {
  font-weight: bold;
}

.loss {
  font-weight: normal;
}

.scores {
  margin-bottom: 0px;
}

.text-left.text-nowrap.td-score {
  border-top:none;
}

.text-right.text-nowrap.td-score {
  border-top:none;
}

.table > tbody > tr > .red {
  background-color: red;
}

.table > tbody > tr > .yellow {
  background-color: yellow;
}

.table td.fit, 
.table th.fit {
    white-space: nowrap;
    width: 1%;
}